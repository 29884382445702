/**
 * https://github.com/rafaelaugustos/react-native-popup-ui
 * Loading spinner, dialog
 * Prevent duplicate popup
 */
import React, { Component, Suspense } from "react";
import { withTranslation } from "react-i18next";
import ModalStack from "../modal/ModalStack";

class Root extends Component {
  render() {
    const { t, navigate } = this.props;

    return (
      <div id="root-style" className="root-style" ref={(c) => (this._root = c)}>
        {this.props.children}
        <ModalStack />
      </div>
    );
  }
}

export default withTranslation()(Root);
