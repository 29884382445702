import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import en from "./resources/language/en";
import th from "./resources/language/th";

const resources = {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
};

const languageDetector = {
  type: "languageDetector",
  async: true,
  init: () => {},
  detect: async (callback) => {
    try {
      const data = localStorage.getItem("lang");

      if (data) {
        return callback(data);
      } else {
        return callback("th");
      }
    } catch (error) {
      console.log("Error reading language", error);
    }
  },
  cacheUserLanguage: async function (language) {
    try {
      localStorage.setItem("lang", language);
    } catch (error) {
      console.log("Error reading language", error);
    }
  },
};

i18n.use(languageDetector).use(Backend).use(initReactI18next).init({
  resources,
  debug: true,
  fallbackLng: "th",
});

export default i18n;
