import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./PornPage.scss";
import { isEqual } from "lodash";
import useApi from "../../api";
import CPListTab, { Tab } from "../../components/tab/CPListTab";
import FlatList from "../../components/listing/FlatList";
import { GetCommon } from "../../../resources/image/image";
import { postPlayItemToParent } from "../../utils/helper";

export default function PornPage() {
  const pornLocalKey = "pList";
  const { t: trans, i18n } = useTranslation();
  const [type, setType] = useState("all");
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const { MovieList } = useApi();
  const Common = GetCommon();

  const filterData = [
    // {
    //   title: trans('movie.hot'),
    //   type: type === 'hot',
    //   onClick: () => {
    //     setType('hot');
    //     filterList('hot');
    //   },
    // },
    {
      title: trans("movie.recent"),
      type: type === "recent",
      onClick: () => {
        setType("recent");
        // filterList('recent');
      },
    },
  ];

  useEffect(() => {
    // Loading.hide();
    //true mean no localdata, default value is falsy mean rendered localdata
    const renderLocal = renderPornUI(null, type, true);
    let dateFrom;
    if (type == "recent") {
      const currentDate = new Date();
      dateFrom = Math.floor(
        currentDate.setDate(currentDate.getDate() - 6) / 1000
      );
    }

    Listing(dateFrom);
  }, [type]);

  function renderPornUI(response, type, renderLocal = false) {
    let res = response;
    const storageKey = pornLocalKey + type;
    const listLocal = JSON.parse(localStorage.getItem(storageKey));
    console.log(listLocal);
    //if api res same with local dont re-render
    if (listLocal && isEqual(listLocal, res) && !renderLocal) {
      return;
    }

    //if local existed render local first, else exit function since no data to render
    if (renderLocal) {
      if (listLocal) {
        res = listLocal;
      } else {
        // return true for indicate whether it render local,
        // default return falsy value mean success,
        // if true mean unsuccess.
        setData([]);
        return true;
      }
    }

    localStorage.setItem(storageKey, JSON.stringify(res));

    setFilter(res.data);
    setData(res.data);
    // filterList(type, res.data);
  }

  function Listing(dateFrom) {
    MovieList({
      type: "adult_movie",
      dateFrom: dateFrom,
      success: (res) => {
        renderPornUI(res, type);
      },
    });
  }

  // const filterList = (type, res) => {
  //   let fil = res || filter;

  //   if (type === 'hot') {
  //     fil = filter.filter((item) => item.option.includes('hot'));
  //   }

  //   if (type === 'recent') {
  //     fil = filter.filter((item) => item.option.includes('recent'));
  //   }

  //   if (Object.keys(fil).length <= 0) {
  //     setData([]);
  //     setStatus(trans('general.list.noRecord'));
  //     return;
  //   }

  //   setData(fil);
  // };

  function RenderItem({ item }) {
    const languageKey = i18n.language;
    const {
      cover,
      [`title_${languageKey}`]: title,
      duration,
      releaseDate,
      actor: attress,
      contentURL,
      [`description_${languageKey}`]: desc,
    } = item;

    const artis = attress ? `• ${attress.split(",").join(`\n• `)}` : "";

    const date =
      releaseDate.indexOf("-") > -1 ? releaseDate.split("-")[0].trim() : "";
    return (
      <div className="porn-render-container">
        <img className="porn-poster" src={cover} />
        <div
          className="porn-content"
          onClick={() => {
            postPlayItemToParent(item);
          }}
        >
          <div>
            <div className="porn-title">{title}</div>
            <div className="porn-date">{`${date} . ${duration}`}</div>
          </div>
          <div className="porn-desc-container">
            <div>
              <div className="porn-desc">{artis}</div>
              <div className="porn-desc">{desc}</div>
            </div>
            <img src={Common.play} className="porn-play-icon" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="porn-sticky-header">
        <CPListTab
          calender={false}
          data={filterData}
          firstTabType={type === "all"}
          firstTabOnClick={() => {
            setType("all");
            // filterList('all');
          }}
          renderItem={(item, index) => {
            return (
              <Tab
                key={index}
                title={item.title}
                type={item.type}
                onClick={item.onClick}
              />
            );
          }}
        />
      </div>
      <FlatList
        data={data}
        getStatus={status}
        renderItem={(item, index) => (
          <RenderItem key={index} item={item} index={index} />
        )}
      />
    </div>
  );
}
