import { useState, useEffect, useLayoutEffect, useRef } from "react";
import "./MoviePage.scss";
import { useTranslation } from "react-i18next";
import CPListTab, { Tab } from "../../components/tab/CPListTab";
import FlatList from "../../components/listing/FlatList";
import api from "../../api";
import { isEqual } from "lodash";
import Modal from "../../components/modal/Modal";
import CPButton from "../../components/button/CPButton";
import { GetCommon } from "../../../resources/image/image";
import { postPlayItemToParent, useNav } from "../../utils/helper";
import { useModal } from "../../context/ModalContext";
import useApi from "../../api";

function MoviePage() {
  const movieLocalKey = "movieList";
  const { t: trans, i18n } = useTranslation();
  const [type, setType] = useState("all");
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState({});
  const [date, setDate] = useState("");
  const Common = GetCommon();
  const navigate = useNav();
  const { MovieList } = useApi();

  const filterData = [
    // {
    //   title: trans("movie.hot"),
    //   type: type === "hot",
    //   onClick: () => {
    //     setType("hot");
    //     filterList("hot");
    //   },
    // },
    {
      title: trans("movie.recent"),
      type: type === "recent",
      onClick: () => {
        setType("recent");

        // Listing(last7day);
        // filterList("recent");
      },
    },
  ];

  useEffect(() => {
    // Loading.hide();
    //true mean no localdata, default value is falsy mean rendered localdata
    const renderLocal = renderMovieUI(null, type, true);
    let dateFrom;
    if (type == "recent") {
      const currentDate = new Date();
      dateFrom = Math.floor(
        currentDate.setDate(currentDate.getDate() - 6) / 1000
      );
    }

    Listing(dateFrom);
  }, [type]);

  function renderMovieUI(response, type, renderLocal = false) {
    let res = response;
    const storageKey = movieLocalKey + type;
    const listLocal = JSON.parse(localStorage.getItem(storageKey));
    //if api res same with local dont re-render

    if (listLocal && isEqual(listLocal, res) && !renderLocal) {
      return;
    }

    //if local existed render local first, else exit function since no data to render
    if (renderLocal) {
      if (listLocal) {
        res = listLocal;
      } else {
        // return true for indicate whether it render local,
        // default return falsy value mean success,
        // if true mean unsuccess.
        setData([]);
        return true;
      }
    }

    localStorage.setItem(storageKey, JSON.stringify(res));

    setFilter(res.data);
    setData(res.data);
    // filterList(type, res.data);
  }

  function Listing(dateFrom) {
    MovieList({
      type: "movie",
      dateFrom: dateFrom,
      success: (res) => {
        renderMovieUI(res, type);
      },
    });
  }

  // const filterList = (type, res) => {
  //   let fil = res || filter;
  //   console.log(`type ${type}`);
  //   console.log(fil);
  //   if (type === "hot") {
  //     fil = filter.filter((item) => item.option.includes("hot"));
  //   }

  //   if (type === "recent") {
  //     fil = filter.filter((item) => item.option.includes("recent"));
  //   }

  //   if (Object.keys(fil).length <= 0) {
  //     setData([]);
  //     setStatus(trans("general.list.noRecord"));
  //     return;
  //   }

  //   setData(fil);
  // };
  // IframeComponent.js

  function RenderItem({ item }) {
    const languageKey = i18n.language;
    const {
      cover,
      [`title_${languageKey}`]: title,
      releaseDate,
      duration,
      [`tags_${languageKey}`]: tags,
      imdb,
    } = item;
    const date =
      releaseDate.indexOf(",") > -1 ? releaseDate.split(",")[1].trim() : "";

    return (
      <div className="movie-render-container">
        <img className="movie-poster" src={cover} />
        <div
          className="movie-content"
          onClick={() => {
            setItem(item);
            setDate(date);
            setTimeout(() => {
              setIsOpen(true);
            }, 200);
          }}
        >
          <div className="movie-title">{title}</div>
          <div className="movie-date">{`${date} . ${tags} . ${duration}`}</div>
          <div className="movie-imdb">
            <div className="movie-imdb-title">{`${trans("movie.imdb")}:`}</div>
            <div className="movie-imdb-rate">{`${imdb}/10.0`}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="movie-popup-container">
          <div className="movie-popup-style">
            <div className="movie-top">
              <img className="movie-poster" src={item.cover} />
              <div className="movie-popup-desc">
                <div className="movie-popup-title">
                  {item[`title_${i18n.language}`]}
                </div>
                <div className="movie-date">{`${date} . ${
                  item[`tags_${i18n.language}`]
                } . ${item.duration}`}</div>
                <div className="movie-popup-imdb">
                  <div className="movie-imdb-title">{`${trans(
                    "movie.imdb"
                  )}:`}</div>
                  <div className="movie-imdb-rate">{`${item.imdb}/10.0`}</div>
                </div>
                <div className="movie-btn-container">
                  <CPButton
                    title={trans("movie.play")}
                    btnStyle={`movie-btn-style`}
                    btnBg={"movie-btn-bg"}
                    textStyle={`movie-btn-txt`}
                    onClick={() => {
                      postPlayItemToParent(item);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="movie-btm">
              <div className="movie-btm-container">
                <div className="movie-1st-row">
                  <div className="movie-popup-rate-imdb">
                    <div>{`${item.imdb}/10`}</div>
                    <div className="movie-popup-rate-title">
                      {trans("movie.imdb")}
                    </div>
                  </div>
                  <div className="movie-popup-rate-rot">
                    <div>{item.rottenTomatoes}</div>
                    <div className="movie-popup-rate-title">
                      {trans("movie.rottenTomatoes")}
                    </div>
                  </div>
                  <div className="movie-popup-rate-meta">
                    <div>{item.metacritic}</div>
                    <div className="movie-popup-rate-title">
                      {trans("movie.metacritic")}
                    </div>
                  </div>
                </div>
                {item.google ? (
                  <div className="movie-2nd-row">
                    <div className="movie-google-rate">{`${item.google} ${trans(
                      "movie.likeThis"
                    )}`}</div>
                    <div className="movie-google-users">
                      {trans("movie.googleUsers")}
                    </div>
                  </div>
                ) : null}

                <div className="movie-3rd-row">
                  <div className="movie-details-desc">
                    {item[`description_${i18n.language}`]}
                  </div>
                  <div className="movie-details">
                    <div className="movie-details-title">{`${trans(
                      "movie.releaseDate"
                    )}`}</div>
                    <div>{item.releaseDate || "N/A"}</div>
                  </div>
                  <div className="movie-details">
                    <div className="movie-details-title">{`${trans(
                      "movie.directors"
                    )}`}</div>
                    <div>{item.directors || "N/A"}</div>
                  </div>
                  <div className="movie-details">
                    <div className="movie-details-title">{`${trans(
                      "movie.boxOffice"
                    )}`}</div>
                    <div>{item.boxOffice || "N/A"}</div>
                  </div>
                  <div className="movie-details">
                    <div className="movie-details-title">{`${trans(
                      "movie.budget"
                    )}`}</div>
                    <div>{item.budget || "N/A"}</div>
                  </div>
                  <div className="movie-details">
                    <div className="movie-details-title">{`${trans(
                      "movie.awards"
                    )}`}</div>
                    <div>{item.awards || "N/A"}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="movie-close-container"
            onClick={() => setIsOpen(false)}
          >
            <img className="movie-close" src={Common.remove} />
          </div>
        </div>
      </Modal>
      <div className="movie-sticky-header">
        <CPListTab
          calender={false}
          data={filterData}
          firstTabType={type === "all"}
          firstTabOnClick={() => {
            setType("all");
            // filterList("all");
          }}
          renderItem={(item, index) => {
            return (
              <Tab
                key={index}
                title={item.title}
                type={item.type}
                onClick={item.onClick}
              />
            );
          }}
        />
      </div>

      <FlatList
        data={data}
        containerStyle={"movie-flatlist"}
        getStatus={status}
        renderItem={(item, index) => (
          <RenderItem key={index} item={item} index={index} />
        )}
      />
    </div>
  );
}

export default MoviePage;
