import { createSlice } from "@reduxjs/toolkit";
import { clientInfo } from "../../resources";

const initialState = {
  contentUrl: clientInfo.defaultAssetUrl,
};

const contentUrlStorage = localStorage.getItem("contentUrl");
let contentUrlObj = JSON.parse(contentUrlStorage);

export const finalInitialState = contentUrlObj
  ? { ...initialState, contentUrl: contentUrlObj }
  : initialState;

export const companySlice = createSlice({
  name: "company",
  initialState: finalInitialState,
});

// export const { setCompany, setMarquee } = companySlice.actions;
export default companySlice.reducer;
