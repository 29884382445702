import React, { useEffect, useState, useRef } from "react";
import "./ErrorModal.scss";
import { useTranslation } from "react-i18next";
import CPButton from "../button/CPButton";
import { useModal } from "../../context/ModalContext";

function ErrorModal({ msg, content }) {
  const { popModal } = useModal();
  const { t: trans } = useTranslation();
  return (
    <div className="errorModal-main-container">
      <div className="errorModal-container">
        <div className="errorModal-content-container">
          <div className="errorModal-title">{msg}</div>
          {content ? <div className="errorModal-content">{content}</div> : null}
        </div>
        <div className="errorModal-btn-container">
          <CPButton
            btnBg={`errorModal-btn-bg`}
            btnStyle={"errorModal-btn"}
            textStyle={"errorModal-btn-txt"}
            title={trans("dialog.btn.ok")}
            onClick={() => {
              popModal();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
