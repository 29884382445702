import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "default",
};

export const stylingSlice = createSlice({
  name: "styling",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      document.body.classList.remove(state.theme);
      document.body.classList.add(action.payload);
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = stylingSlice.actions;
export default stylingSlice.reducer;
