import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./CPListTab.scss";

CPListTab.propsTypes = {
  data: PropTypes.array,
  firstTabOnClick: PropTypes.func,
  firstTabType: PropTypes.string,
  firstTabTitle: PropTypes.string,
  renderItem: PropTypes.any,
  containerStyle: PropTypes.any,
  todayOnClick: PropTypes.func,
  yesterdayOnClick: PropTypes.func,
  last7OnClick: PropTypes.func,
  last15OnClick: PropTypes.func,
  last30OnClick: PropTypes.func,
  calender: PropTypes.bool,
  container: PropTypes.any,
};

CPListTab.defaultProps = {
  data: [
    {
      item: "listing 1",
    },
  ],
  title: "Title",
  onClick: () => alert("list tab"),
  todayOnClick: () => alert("today"),
  yesterdayOnClick: () => alert("yesterday"),
  last7OnClick: () => alert("last 7"),
  last15OnClick: () => alert("last 15"),
  last30OnClick: () => alert("last 30"),
  calender: true,
};

export function Tab({ title, onClick, type, style }) {
  return (
    <div className={`tab-list-btn ${style}`} onClick={onClick}>
      <div className={type ? "tab-list-txt-selected" : "tab-list-txt-unSelect"}>
        {title}
      </div>
      {type && (
        <div
          className="tab-list-underline-selected"
          style={{ width: title.length * 11 }}
        />
      )}
    </div>
  );
}

export default function CPListTab({
  data,
  firstTabOnClick,
  firstTabType,
  firstTabTitle,
  containerStyle,
  renderItem,
  calender,
  container,
}) {
  const { t: trans } = useTranslation();
  const all = trans("game.all");
  return (
    <div className={`tab-list-container ${container}`}>
      <div className="tab-list-style">
        <Tab
          title={firstTabTitle ? firstTabTitle : all}
          type={firstTabType}
          onClick={firstTabOnClick}
          style={"tab-all"}
        />
        <div
          className={`tab-rest-container ${containerStyle}`}
          style={{
            marginRight: calender ? 10 : 0,
            width: calender ? "60%" : "100%",
          }}
        >
          {data.map((item, index) => {
            return renderItem(item, index);
          })}
        </div>
      </div>
    </div>
  );
}
