import React from "react";
import PropTypes from "prop-types";
import "./CPButton.scss";

CPButton.propTypes = {
  btnBg: PropTypes.string,
  btnStyle: PropTypes.string,
  prefab: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textStyle: PropTypes.string,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  rightIcon: PropTypes.string,
  onClick: PropTypes.func,
  deco: PropTypes.bool,
  decoStyle: PropTypes.string,
  disabled: PropTypes.any,
  name: PropTypes.string,
};

CPButton.defaultProps = {
  prefab: "main",
  title: "Button",
  onClick: () => alert("button"),
  deco: false,
  disabled: false,
};

export default function CPButton({
  btnBg,
  btnStyle,
  prefab,
  title,
  textStyle,
  icon,
  iconStyle,
  rightIcon,
  onClick,
  deco,
  decoStyle,
  disabled,
  name,
}) {
  // const gradient = disabled
  //   ? 'btn-disabled'
  //   : prefab === 'main'
  //   ? 'btn-mainPrefab'
  //   : 'btn-subPrefab';

  const gradient = disabled
    ? "btn-disabled"
    : btnBg
    ? btnBg
    : prefab === "main"
    ? "btn-mainPrefab"
    : "btn-subPrefab";

  return (
    <button
      disabled={disabled}
      name={name}
      style={{ boxShadow: disabled && "none" }}
      className={`btn-container ${btnStyle} ${gradient}`}
      onClick={onClick}
    >
      {icon ? (
        <img
          src={icon}
          alt={"cpbtn-icon"}
          className={`btn-icon ${iconStyle}`}
        />
      ) : null}
      <div
        className={`btn-textStyle ${textStyle} ${
          disabled && "btn-disabled-textStyle"
        }`}
      >
        {title}
      </div>
      <div className="btn-rightIcon">
        {rightIcon ? (
          <img
            src={rightIcon}
            alt={"cpbtn-icon"}
            className={`btn-icon ${iconStyle}`}
          />
        ) : null}
      </div>
      {deco ? <div className={`btn-deco ${decoStyle}`} /> : null}
    </button>
  );
}
