import React, { createContext, useState, useContext, useEffect } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalStack, setModalStack] = useState([]);

  const popLastAndPushModal = (modalContent) => {
    popModal();
    pushModal(modalContent);
  };

  const pushModal = (modalContent) => {
    console.log(`push`);
    console.log(modalContent);
    setModalStack((prevStack) => {
      return [...prevStack, modalContent];
    });
  };

  const popModal = () => {
    setModalStack((prevStack) => {
      if (prevStack.length === 1) {
        document.body.style.overflow = "scroll";
      }
      return prevStack.slice(0, -1);
    });
  };

  const clearModals = () => {
    document.body.style.overflow = "scroll";
    setModalStack([]);
  };

  return (
    <ModalContext.Provider
      value={{
        modalStack,
        pushModal,
        popModal,
        popLastAndPushModal,
        clearModals,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
