import React, {useEffect} from 'react';
import './Modal.scss';

function Modal({children, isOpen, handleClose, showAnim = true}) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }

    const closeOnEscapeKey = (e) => {
      return e.key === 'Escape' && isOpen ? handleClose() : null;
    };
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose, isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="modal"
      style={{animation: showAnim ? 'modalAnim 0.5s ease-out' : 'none'}}>
      <div className="modal-content">{children}</div>
    </div>
  );
}

export default Modal;
