import { useNavigate } from "react-router-dom";

export const useNav = () => {
  const navigate = useNavigate();

  return navigate;
};

export const postPlayItemToParent = (item) => {
  const messageData = {
    action: "play",
    data: item,
  };

  // Send the message to the parent document
  window.parent.postMessage(messageData, "*");
};
