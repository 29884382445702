import React from "react";
import { useModal } from "../../context/ModalContext";
import ContentModal from "./ContentModal";

const ModalStack = () => {
  const { modalStack, popModal } = useModal();

  return (
    <div>
      {modalStack.map((modalObj, index) => (
        <ContentModal
          key={index}
          isOpen={index === modalStack.length - 1}
          handleClose={popModal}
          showAnim={modalObj?.showAnim != null ? modalObj.showAnim : true}
        >
          {modalObj.content}
        </ContentModal>
      ))}
    </div>
  );
};

export default ModalStack;
