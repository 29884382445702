import { configureStore } from "@reduxjs/toolkit";
import companySlice from "./companySlice";
import stylingSlice from "./stylingSlice";

const isPrd = process.env.NODE_ENV === "production";

export const store = configureStore({
  reducer: {
    styling: stylingSlice,
    company: companySlice,
  },
});
