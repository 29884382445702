import { useEffect } from "react";
import "./App.scss";
import api from "./common/api";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import PornPage from "./common/pages/porn/PornPage";
import MoviePage from "./common/pages/movie/MoviePage";
import ErrorPage from "./common/pages/error/ErrorPage";
import Root from "./common/components/root";
import { useNav } from "./common/utils/helper";
import { ModalProvider } from "./common/context/ModalContext";
import { useDispatch } from "react-redux";
import { setTheme } from "./common/redux/stylingSlice";
import i18n from "./i18n";

function App() {
  // Remove console log in production
  if (process.env.NODE_ENV === "production")
    console.log = function no_console() {};

  const navigate = useNav();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const language = searchParams.get("language");
  const theme = searchParams.get("theme");

  useEffect(() => {
    let finalTheme = "default";
    if (theme) {
      finalTheme = theme;
    }
    dispatch(setTheme(theme));

    let finalLang = "th";
    if (language) {
      finalLang = language;
    }
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    // Add an event listener to listen for messages
    const handleMessage = (event) => {
      // Handle the received message
      const receivedMessage = event.data;
      if (receivedMessage.language) {
        i18n.changeLanguage(receivedMessage.language);
      }

      if (receivedMessage.theme) {
        dispatch(setTheme(receivedMessage.theme));
      }
    };

    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <ModalProvider>
      <Root navigate={navigate}>
        <Routes>
          <Route path="/porn" element={<PornPage />} />
          <Route path="/movie" element={<MoviePage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Root>
    </ModalProvider>
  );
}

export default App;
