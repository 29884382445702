import axios from "axios";
import { api } from "./Action";
import { clientInfo } from "../../resources";
import { useModal } from "../context/ModalContext";
import ErrorModal from "../components/modal/ErrorModal";

function useApi() {
  const { pushModal } = useModal();
  // const api_path = '/api/rest';
  const api_path = "/api/rest/ping";
  const call_path = "";

  const domain = clientInfo.domains;

  const start = axios.create({
    method: "post",
  });

  start.interceptors.request.use(
    async (config) => {
      //   let baseURL = "";

      //   for (let i = 0; i < domain.length; i++) {
      //     let available = domain[i];
      //     // const check = await fetch(`${available}/api/rest/version`)
      //     const check = await fetch(`${available}${api_path}`)
      //       .then((res) => {
      //         return res.ok;
      //       })
      //       .catch((error) => {
      //         console.log("** Error: Domain not working **", error);
      //       });

      //     if (check) {
      //       baseURL = available;
      //       break;
      //     }
      //   }

      let baseURL = domain[0];
      console.log("baseURL: ", baseURL);
      // const {token, profile} = store.getState().user;

      config = {
        ...config,
        baseURL: `${baseURL}${call_path}`,
        // baseURL: `${baseURL}`,

        headers: {
          // Accept: 'application/json',
          // 'Content-Type': 'application/json; charset=utf-8',
          // ...config.headers,
          // "Accept-Language": i18n.language,
          // 'x-token': token,
          // 'x-userid': profile.userId,
          "x-token": "686b769113193b3a4c968a5ad4f92e63",
          "x-userid": "5fe0cc5e-f5f9-4e8f-8b16-01099aab2e56",
          Authorization: `Bearer ${process.env.REACT_APP_MOVIE_API_KEY}`,
        },
      };

      // Loading.show();
      return config;
    },
    (error) => {
      // Loading.show();
      return Promise.reject(error);
    }
  );

  start.interceptors.response.use(
    (response) => {
      console.log(`=> Status Code `, response.status);
      // Loading.hide();

      if (response.status) {
        switch (response.status) {
          case 200:
            return response.data;

          default:
            return response.data;
        }
      } else {
        return response;
      }
    },
    (error) => {
      console.log(`=> Status Code `, error.response.status);
      pushModal({
        content: (
          <ErrorModal
            msg={
              "Something went wrong, Please contact your administrator for help."
            }
          />
        ),
      });

      return Promise.reject(error);
    }
  );

  function reqApi(
    { act, data, success, error },
    { dialog = true, spinner = false } = {}
  ) {
    //   if (spinner) {
    //     Loading.show();
    //   }
    start({
      url: act,
      data,
    })
      .then(async (json) => {
        console.log(`call api success block ! `);
        console.log(json);
        //   if (spinner) {
        //     Loading.hide();
        //   }
        //   const {status, data, errcode, msg, 'x-token': xToken} = json;
        const { status, data, errcode, msg } = json;
        //   const {token: curToken} = store.getState().user;

        console.log(`=> Action: `, act);
        console.log(`=> Status: `, status);
        console.log(`=> ErrCode: `, errcode);
        console.log(`=> Msg: `, msg);
        //   console.log(`=> Token: `, xToken);
        console.log(`=> Data: `, data);

        //   if (curToken !== xToken && xToken) {
        //     store.dispatch(token(xToken));
        //     sessionStorage.setItem('token', JSON.stringify(xToken));
        //   }

        if (errcode) {
          return Promise.reject({ response: { data: json } });
        }

        const isEmpty = (item) => {
          return Object.keys(item).length === 0;
        };

        if (success) success(isEmpty(data) ? { noData: json } : json);
      })
      .catch((err) => {
        console.log(`call api catch block ! `);
        console.log(err);
        //   if (spinner) {
        //     Loading.hide();
        //   }
        //   const {status, errcode, msg} = err.response?.data || {};

        //   console.log('=> Catch Err: ', err);
        //   console.log(`=> Catch Action: `, act);
        //   console.log(`=> Catch Status: `, status);
        //   console.log(`=> Catch ErrCode: `, errcode);
        //   console.log(`=> Catch Msg: `, msg);

        //   switch (errcode) {
        //     case '554':
        //       Dialog.show({
        //         show: true,
        //         msg: trans('dialog.err.depositMaintenance'),
        //         type: 'error',
        //         btnAct: () => {
        //           navigator(-1);
        //         },
        //       });
        //       break;

        //     case '444':
        //       Dialog.show({
        //         show: true,
        //         msg: trans('dialog.msg.sessionExp'),
        //         type: 'error',
        //         btnAct: () => {
        //           sessionStorage.clear();
        //           window.location.reload();
        //         },
        //       });
        //       break;

        //     default:
        //       if (dialog) {
        //         Dialog.show({
        //           show: true,
        //           msg: errcode
        //             ? `${errcode}: ${msg}`
        //             : 'Something went wrong, Please contact your administrator for help.',
        //           type: 'error',
        //           btnAct: () => {
        //             // Loading.hide();
        //           },
        //         });
        //       }
        //       break;
        //   }

        if (error) error(err.response?.data);
      });
  }

  /**
   * @param {object} props
   * @param {String} props.type
   * @param {String} props.dateFrom
   * @param {function} [props.success]
   * @param {function} [props.error]
   */
  async function MovieList({ type, dateFrom, success, error }) {
    return reqApi({
      act: api.MovieList,
      data: {
        Type: type,
        DateFrom: dateFrom,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (res) => {
        if (error) error(res);
      },
    });
  }

  return { MovieList };
}

export default useApi;
