import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./FlatList.scss";

FlatList.propTypes = {
  data: PropTypes.array,
  horizontal: PropTypes.bool,
  renderItem: PropTypes.any,
  containerStyle: PropTypes.any,
  scrollEnabled: PropTypes.bool,
  hideScrollBar: PropTypes.bool,
  getStatus: PropTypes.string,
};

FlatList.defaultProps = {
  scrollEnabled: true,
  hideScrollBar: false,
  horizontal: false,
  getStatus: "",
};

export default function FlatList({
  data,
  horizontal,
  renderItem,
  containerStyle,
  hideScrollBar,
  getStatus,
}) {
  const { t: trans } = useTranslation();

  return (
    <div
      className={`${
        horizontal ? `flatList-horizontal-container` : `flatList-container`
      }
    ${hideScrollBar ? `hide-scrollbar` : null}
    ${containerStyle}`}
    >
      {data && data.length === 0 ? (
        <div className="noData">
          {getStatus ? getStatus : trans("game.loading")}
        </div>
      ) : (
        data.map((item, index) => {
          return renderItem(item, index);
        })
      )}
    </div>
  );
}
